import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import styles from "./Book.module.css";
import emailjs from "@emailjs/browser";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import { Spinner } from "@chakra-ui/react";
// import Spinnerstyle from './Spinner.module.css'
import { IconContext } from "react-icons";
import { BsFillCreditCard2FrontFill, BsFillHousesFill } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { GiRedCarpet, GiCycle, GiBrickWall } from "react-icons/gi";
import { ImMoveDown } from "react-icons/im";
import { LuMicrowave } from "react-icons/lu";
import {
  MdOutlineWindow,
  MdOutlineSensorWindow,
  MdBlinds,
  MdBalcony,
  MdDeck,
  MdBathroom,
  MdOutlineBathroom,
  MdOutlineTimer,
  MdGppGood,
  MdConnectWithoutContact,
} from "react-icons/md";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { AiOutlineLike } from "react-icons/ai";
import { PiTreeEvergreenFill } from "react-icons/pi";
import { RiFridgeFill, RiVisaFill } from "react-icons/ri";
import { BiSolidBed } from "react-icons/bi";
import { GiFamilyHouse } from "react-icons/gi";

import CardImage from "../images/card.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";

const Book = ({ setAlert }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  // let initialPrice = "119";
  // eslint-disable-next-line
  let initialPrice = "0";
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [Price, setPrice] = useState("0");
  const [cardNumber, setCardNumber] = useState("");
  const [MMYY, setMMYY] = useState("");
  const [cvc, setCVC] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [Discount, setDiscount] = useState("One Time Cleaning");
  const [afterDiscount, setAfterDiscount] = useState("0");
  const [AfterBed, setAfterBed] = useState(0);
  const [serviceSelected, setserviceSelected] = useState("Flat Rate");
  const [typeOfCleaningSelected, settypeOfCleaningSelected] =
    useState("Standard Cleaning");

  const [formDataSource, setFormDataSource] = useState("");
  // Type of Service
  const handleChangeService = (event) => {
    resetValues();
    const selectedService = event.target.value;
    setserviceSelected(selectedService);
  };

  //HANDLE CARD NUMBER
  const handleChangeMY = (event) => {
    let input = event.target.value;

    // Remove any non-numeric characters
    input = input.replace(/\D/g, "");

    // Add a '/' between the month and year
    if (input.length > 2) {
      input = input.slice(0, 2) + "/" + input.slice(2);

      // Restrict to MM/YY format (e.g., 12/23)
      if (input.length > 5) {
        input = input.slice(0, 5);
      }
    }

    // Update state and set the formatted value
    setMMYY(input);
  };

  //HANDLE CVC LENGTH
  const handleChangeCVC = (event) => {
    // Ensure that the input only contains numeric characters
    const numericValue = event.target.value.replace(/[^0-9]/g, "");

    // Limit the length to 4 digits
    const limitedLengthValue = numericValue.slice(0, 4);

    // Update the state with the sanitized numeric value
    setCVC(limitedLengthValue);
  };

  // No.of Beds
  const [bedSelected, setBedSelected] = useState("");
  const handleBedonChange = (event) => {
    const selectedBed = event.target.value;
    setBedSelected(selectedBed);
  };
  // No. of Hours
  const [hoursSelected, setHoursSelected] = useState("");
  const handleHoursonChange = (event) => {
    const selectedValue = event.target.value;
    setHoursSelected(selectedValue);
  };

  const handleTypeOfCleaning = (event) => {
    const selectedTypeOfCleaning = event.target.value;
    settypeOfCleaningSelected(selectedTypeOfCleaning);
    setserviceSelected("Flat Rate");
    setBedSelected("");
    setHoursSelected("");
    resetValues();
  };

  const resetValues = () => {
    setValue1(0);
    setValue2(0);
    setValue3(0);
    setValue4(0);
    setValue5(0);
    setValue6(0);
    setValue7(0);
    setValue8(0);
    setValue9("No");
    setValue10("No");
    setValue11("No");
    setValue12(0);
    setValue13(0);
    setValue14(0);
    setValue15(0);
    setValue16(0);
    setValue17(0);
    setValue18(0);
    setShowInput1(false);
    setShowInput2(false);
    setShowInput3(false);
    setShowInput4(false);
    setShowInput5(false);
    setShowInput6(false);
    setShowInput7(false);
    setShowInput8(false);
    setShowInput12(false);
    setShowInput13(false);
    setShowInput14(false);
    setShowInput15(false);
    setShowInput16(false);
    setShowInput17(false);
    setShowInput18(false);
  };

  useEffect(() => {
    if (serviceSelected === "Flat Rate") {
      setHoursSelected("");
    } else {
      setBedSelected("");
    }
  }, [serviceSelected]);

  useEffect(() => {
    let bedPrice = 0;
    if (typeOfCleaningSelected === "Standard Cleaning") {
      switch (bedSelected) {
        case "1 Bed 1 Bath":
          bedPrice = 150;
          break;
        case "2 Bed 1 Bath":
          bedPrice = 162;
          break;
        case "3 Bed 1 Bath":
          bedPrice = 199;
          break;
        case "4 Bed 1 Bath":
          bedPrice = 242;
          break;
        case "5 Bed 1 Bath":
          bedPrice = 265;
          break;
        case "6 Bed 1 Bath":
          bedPrice = 320;
          break;
        default:
          break;
      }
    } else if (typeOfCleaningSelected === "Move In/Out Clean") {
      switch (bedSelected) {
        case "1 Bed 1 Bath":
          bedPrice = 385;
          break;
        case "2 Bed 1 Bath":
          bedPrice = 445;
          break;
        case "3 Bed 1 Bath":
          bedPrice = 545;
          break;
        case "4 Bed 1 Bath":
          bedPrice = 660;
          break;
        case "5 Bed 1 Bath":
          bedPrice = 900;
          break;
        case "6 Bed 1 Bath":
          bedPrice = 1040;
          break;
        case "7 Bed 1 Bath":
          bedPrice = 1350;
          break;
        default:
          break;
      }
    } else if (typeOfCleaningSelected === "Deep Cleaning") {
      switch (bedSelected) {
        case "1 Bed 1 Bath":
          bedPrice = 222;
          break;
        case "2 Bed 1 Bath":
          bedPrice = 230;
          break;
        case "3 Bed 1 Bath":
          bedPrice = 268;
          break;
        case "4 Bed 1 Bath":
          bedPrice = 346;
          break;
        case "5 Bed 1 Bath":
          bedPrice = 396;
          break;
        case "6 Bed 1 Bath":
          bedPrice = 450;
          break;
        case "7 Bed 1 Bath":
          bedPrice = 515;
          break;
        default:
          break;
      }
    }
    setAfterBed(bedPrice);
    setAfterDiscount(bedPrice);
    setPrice(bedPrice);
  }, [bedSelected, typeOfCleaningSelected]);

  useEffect(() => {
    let hourPrice = 0;
    if (typeOfCleaningSelected === "Standard Cleaning") {
      if (hoursSelected === "1.5") {
        hourPrice = 58;
      } else if (hoursSelected === "3") {
        hourPrice = 165;
      } else {
        hourPrice = hoursSelected * 58;
      }
    }
    setAfterBed(hourPrice);
    setAfterDiscount(hourPrice);
    setPrice(hourPrice);
  }, [hoursSelected, typeOfCleaningSelected]);

  // Extras

  const [showInput3, setShowInput3] = useState(false);
  const [showInput4, setShowInput4] = useState(false);
  const [showInput5, setShowInput5] = useState(false);
  const [showInput6, setShowInput6] = useState(false);
  const [showInput7, setShowInput7] = useState(false);
  const [showInput8, setShowInput8] = useState(false);
  const [showInput12, setShowInput12] = useState(false);
  const [showInput13, setShowInput13] = useState(false);
  const [showInput14, setShowInput14] = useState(false);
  const [showInput15, setShowInput15] = useState(false);
  const [showInput16, setShowInput16] = useState(false);
  const [showInput17, setShowInput17] = useState(false);
  const [showInput18, setShowInput18] = useState(false);

  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);
  const [value5, setValue5] = useState(0);
  const [value6, setValue6] = useState(0);
  const [value7, setValue7] = useState(0);
  const [value8, setValue8] = useState(0);
  const [value9, setValue9] = useState("No");
  const [value10, setValue10] = useState("No");
  const [value11, setValue11] = useState("No");
  const [value12, setValue12] = useState(0);
  const [value13, setValue13] = useState(0);
  const [value14, setValue14] = useState(0);
  const [value15, setValue15] = useState(0);
  const [value16, setValue16] = useState(0);
  const [value17, setValue17] = useState(0);
  const [value18, setValue18] = useState(0);

  // eslint-disable-next-line
  const [valueTime, setValueTime] = useState("8AM - 5PM");

  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [showInput1, setShowInput1] = useState(false);
  const [showInput2, setShowInput2] = useState(false);

  // error
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [LnameError, setLnameError] = useState("");
  const [FnameError, setFnameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [suburbError, setSuburbError] = useState("");

  const handleInputChange1 = (e) => {
    setValue1(e);
    // if (e > 6) {
    //   alert("Please enter a number between 0 and 6.");
    //   setValue1(0);
    // }
  };
  const handleInputChange2 = (e) => {
    setValue2(e);
    // if (e > 6) {
    //   alert("Please enter a number between 0 and 6.");
    //   setValue2(0);
    // }
  };
  const handleInputChange3 = (e) => {
    setValue3(e);
    // if (e.target.value > 6) {
    //   alert("Please enter a number between 0 and 6.");
    //   setValue3(0);
    // }
  };
  const handleInputChange4 = (e) => {
    setValue4(e);
    // if (e > 6) {
    //   alert("Please enter a number between 0 and 6.");
    //   setValue4(0);
    // }
  };
  const handleInputChange5 = (e) => {
    setValue5(e);
  };
  const handleInputChange6 = (e) => {
    setValue6(e);
  };
  const handleInputChange7 = (e) => {
    setValue7(e);
  };
  const handleInputChange8 = (e) => {
    setValue8(e);
  };
  const handleInputChange9 = (e) => {
    if (e.target.value) {
      setValue9("yes");
    } else {
      setValue9("no");
    }
  };
  const handleInputChange10 = (e) => {
    if (e.target.value) {
      setValue10("yes");
    } else {
      setValue10("no");
    }
  };
  const handleInputChange11 = (e) => {
    if (e.target.value) {
      setValue11("yes");
    } else {
      setValue11("no");
    }
  };
  const handleInputChange12 = (e) => {
    setValue12(e);
  };
  const handleInputChange13 = (e) => {
    setValue13(e);
  };
  const handleInputChange14 = (e) => {
    setValue14(e);
  };
  const handleInputChange15 = (e) => {
    setValue15(e);
  };
  const handleInputChange16 = (e) => {
    setValue16(e);
  };
  const handleInputChange17 = (e) => {
    setValue17(e);
  };
  const handleInputChange18 = (e) => {
    setValue18(e);
  };

  const handleIconsClick = (iconIndex) => {
    if (
      (serviceSelected === "Flat Rate" && bedSelected) ||
      (serviceSelected === "Hourly Service" && hoursSelected)
    ) {
      setShowInput1(iconIndex === 1);
      setShowInput2(iconIndex === 2);
      setShowInput3(iconIndex === 3);
      setShowInput4(iconIndex === 4);
      setShowInput5(iconIndex === 5);
      setShowInput6(iconIndex === 6);
      setShowInput7(iconIndex === 7);
      setShowInput8(iconIndex === 8);
      setShowInput12(iconIndex === 12);
      setShowInput13(iconIndex === 13);
      setShowInput14(iconIndex === 14);
      setShowInput15(iconIndex === 15);
      setShowInput16(iconIndex === 16);
      setShowInput17(iconIndex === 17);
      setShowInput18(iconIndex === 18);
      if (iconIndex === 9) {
        setValue9(value9 === "yes" ? "no" : "yes");
      }
      if (iconIndex === 10) {
        setValue10(value10 === "yes" ? "no" : "yes");
      }
      if (iconIndex === 11) {
        setValue11(value11 === "yes" ? "no" : "yes");
      }
    } else {
      alert("Select the Service Hours or No. of Beds First");
    }
  };
  // eslint-disable-next-line
  const [ExtraPrice, setExtraPrice] = useState(0);
  useEffect(() => {
    let updatedExtraPrice = 0;
    if (
      (serviceSelected === "Flat Rate" && bedSelected) ||
      (serviceSelected === "Hourly Service" && hoursSelected)
    ) {
      // CLEAN REFRIGERATOR
      if (value1 > 0) {
        updatedExtraPrice += value1 * 45;
      }

      //OVEN CLEANING
      if (value2 > 0) {
        updatedExtraPrice += value2 * 45;
      }

      //SMALL BALCONY
      if (value3 > 0) {
        updatedExtraPrice += value3 * 32;
      }

      //LARGE BALCONY
      if (value4 > 0) {
        updatedExtraPrice += value4 * 63;
      }

      //INTERIOR WINDOWS
      if (value5 > 0) {
        updatedExtraPrice += value5 * 20;
      }

      //CARPET CLEANING
      if (value6 > 0) {
        updatedExtraPrice += value6 * 90;
      }

      //SPOT CLEAN WALLS
      if (value7 > 0) {
        updatedExtraPrice += value7 * 35;
      }

      //CEILING TO FLOOR GLASS WINDOW
      if (value8 > 0) {
        updatedExtraPrice += value8 * 18;
      }

      //DECK CLEANING
      if (value9 === "yes") {
        updatedExtraPrice += 33;
      }

      //PATIO CLEANING
      if (value10 === "yes") {
        updatedExtraPrice += 33;
      }

      //EXTRA DIRTY
      if (value11 === "yes") {
        updatedExtraPrice += 80;
      }

      //ADDITIONAL FULL BATHROOM - $22
      if (value12 > 0) {
        if (typeOfCleaningSelected === "Standard Cleaning") {
          updatedExtraPrice += value12 * 22;
        } else {
          updatedExtraPrice += value12 * 25;
        }
      }

      //ADDITONAL HALF BATHROOM - $15
      if (value13 > 0) {
        updatedExtraPrice += value13 * 15;
      }

      //EXTERIOR WINDOW
      if (value14 > 0) {
        updatedExtraPrice += value14 * 15;
      }

      //INSIDE CUPBOARD
      if (value15 > 0) {
        updatedExtraPrice += value15 * 55;
      }

      //CHANGE BEDSHEET
      if (value16 > 0) {
        updatedExtraPrice += value16 * 15;
      }

      //WET WIPE BLINDS
      if (value17 > 0) {
        updatedExtraPrice += value17 * 33;
      }

      //EXTRA STOREY
      if (value18 > 0) {
        if (typeOfCleaningSelected === "Standard Cleaning") {
          updatedExtraPrice += value18 * 40;
        }
        if (typeOfCleaningSelected === "Move In/Out Clean") {
          updatedExtraPrice += value18 * 50;
        }
      }
    }
    setExtraPrice(updatedExtraPrice);
    setPrice(AfterBed + updatedExtraPrice);
    setAfterDiscount(AfterBed + updatedExtraPrice);
  }, [
    value1,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
    value8,
    value9,
    value10,
    value11,
    value12,
    value13,
    value14,
    value15,
    value16,
    value17,
    value18,
    ExtraPrice,
    AfterBed,
    afterDiscount,
    bedSelected,
    hoursSelected,
    serviceSelected,
    typeOfCleaningSelected,
  ]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const today = new Date();
  const minDate = today;
  // Discount
  // eslint-disable-next-line
  const handleDiscountClick = (discount) => {
    setDiscount(discount);
  };
  // const handleDiscountClick = useCallback((discount) => {
  //     setDiscount(discount);
  // }, []);

  // Card
  const handleChange = (e) => {
    const input = e.target.value.replace(/\D/g, "").substring(0, 16);
    const spacedInput = input.replace(/(\d{4})(?=\d)/g, "$1 ");
    setCardNumber(spacedInput);
  };

  const [phone, setPhone] = useState("");

  const handleChangePhone = (e) => {
    const inputPhone = e.target.value;
    // Remove any non-digit characters from the input
    const sanitizedPhone = inputPhone.replace(/\D/g, "");

    // Limit the phone number to 10 digits
    if (sanitizedPhone.length <= 10) {
      setPhone(sanitizedPhone);
    }
    if (inputPhone.match(/^\d{10}$/)) {
      setPhoneError("");
    } else {
      setPhoneError("Phone number must be exactly 10 digits");
    }
  };

  const handleChangeFirstName = (event) => {
    let value = event.target.value;
    if (value.length >= 2) {
      setFnameError();
    } else {
      setFnameError("First name must be at least 2 characters long");
    }
  };

  const handleChangeSuburb = (event) => {
    let value = event.target.value;
    if (value.length >= 2) {
      setSuburbError();
    } else {
      setSuburbError("Suburb must be at least 2 characters long");
    }
  };

  const handleChangeLastName = (event) => {
    let value = event.target.value;
    if (value.length >= 2) {
      setLnameError();
    } else {
      setLnameError("Last name must be at least 2 characters long");
    }
  };

  const handleEmailPattern = (e) => {
    if (e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const handleChangeAddress = (event) => {
    let value = event.target.value;
    if (value.length >= 2) {
      setAddressError();
    } else {
      setAddressError("Address must be at least 2 characters long");
    }
  };
  // initialPrice = useMemo(() => Price, [Price]);
  // Memoized computation for CCFEE
  const CCFEE = useMemo(
    () => Math.ceil(parseInt(Price, 10) * 0.0125 * 100) / 100,
    [Price]
  );
  //
  const FirstService = Price + CCFEE;

  // eslint-disable-next-line
  initialPrice = Price;

  // const FirstService = useMemo(() => Price + CCFEE, [Price, CCFEE]);

  // initialPrice = Price;
  // setPrice(FirstService);
  // eslint-disable-next-line
  const handleDiscountChange = useCallback(() => {
    // setPrice(FirstService)
    setAfterDiscount(FirstService);
    // setAfterDiscount()
    switch (Discount) {
      case "One Time Cleaning":
        // Code for 'One Time Cleaning' discount
        // setAfterDiscount(Price);
        break;
      case "Weekly - 15% Off":
        // setAfterDiscount(FirstService * 0.9);

        setAfterDiscount((prevAfterDiscount) => prevAfterDiscount * 0.85);
        break;
      case "Fortnightly - 10% Off":
        setAfterDiscount((prevAfterDiscount) => prevAfterDiscount * 0.9);
        break;
      case "Monthly - 5% Off":
        setAfterDiscount((prevAfterDiscount) => prevAfterDiscount * 0.95);
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [Discount, Price]);

  // Invoke the handleDiscountChange callback when the Discount changes
  React.useEffect(() => {
    handleDiscountChange();
  }, [handleDiscountChange, handleDiscountClick]);

  const total = useMemo(
    () => {
      if (Discount === "One Time Cleaning") {
        const numericAfterDiscount = parseFloat(Price + CCFEE);
        return isNaN(numericAfterDiscount)
          ? 0
          : Number(numericAfterDiscount.toFixed(2));
      } else {
        const numericAfterDiscount = parseFloat(afterDiscount);
        return isNaN(numericAfterDiscount)
          ? 0
          : Number(numericAfterDiscount.toFixed(2));
      }
    },
    // eslint-disable-next-line
    [afterDiscount, Price, Discount]
  );

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e2c8kl6",
        "template_3jbwkuk",
        form.current,
        "HE-LHRT4QxOU2-oYm"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("EMAIL SENT TO ADMIN");
          setAlert(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const sendEmailClient = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e2c8kl6",
        "template_gerx6sm",
        form.current,
        "HE-LHRT4QxOU2-oYm"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("EMAIL SENT TO CLIENT");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleSubmitEmail = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setLoading(true);
    sendEmail(e);
    sendEmailClient(e);

    const formData = new FormData(form.current);
    const extras = [];
    if (formData.get("FullBathroom") > 0) {
      extras.push("Additional Full Bathroom: " + formData.get("FullBathroom"));
    }
    if (formData.get("HalfBathroom") > 0) {
      extras.push("Additional Half Bathroom: " + formData.get("HalfBathroom"));
    }
    if (formData.get("CleanRefrigerator") > 0) {
      extras.push("Clean Refrigerator: " + formData.get("CleanRefrigerator"));
    }
    if (formData.get("OvenCleaning") > 0) {
      extras.push("Oven Cleaning: " + formData.get("OvenCleaning"));
    }
    if (formData.get("SmallBalcony") > 0) {
      extras.push("Small Balcony: " + formData.get("SmallBalcony"));
    }
    if (formData.get("LargeBalcony") > 0) {
      extras.push("Large Balcony: " + formData.get("LargeBalcony"));
    }
    if (formData.get("InteriorWindows") > 0) {
      extras.push("Interior Windows: " + formData.get("InteriorWindows"));
    }
    if (formData.get("CarpetSteamClean") > 0) {
      extras.push("Carpet Steam Clean: " + formData.get("CarpetSteamClean"));
    }
    if (formData.get("CeilingFloor") > 0) {
      extras.push("Ceiling Floor: " + formData.get("CeilingFloor"));
    }
    if (value9 === "yes") {
      extras.push("Deck Cleaning");
    }
    if (value11 === "yes") {
      extras.push("Extra Dirty");
    }
    if (formData.get("ExtraStorey") > 0) {
      extras.push("Extra Storey: " + formData.get("ExtraStorey"));
    }
    if (formData.get("ExteriorWindow") > 0) {
      extras.push("Exterior Window: " + formData.get("ExteriorWindow"));
    }
    if (formData.get("InsideCupboard") > 0) {
      extras.push("Inside Cupboard: " + formData.get("InsideCupboard"));
    }
    if (formData.get("ChangeBedsheet") > 0) {
      extras.push("Change Bedsheet: " + formData.get("ChangeBedsheet"));
    }
    if (formData.get("WetWipeBlinds") > 0) {
      extras.push("Wet Wipe Blinds: " + formData.get("WetWipeBlinds"));
    }
    if (formData.get("SpotCleanWalls") > 0) {
      extras.push("Spot Clean Walls: " + formData.get("SpotCleanWalls"));
    }
    if (value10 === "yes") {
      extras.push("Patio Cleaning");
    }

    const formattedDate = selectedDate
      ? selectedDate.toISOString().split("T")[0]
      : null;
    const discountData =
      Discount === "One Time Cleaning"
        ? "0"
        : Discount === "Weekly - 15% Off"
        ? "15"
        : Discount === "Fortnightly - 10% Off"
        ? "10"
        : "5";
    postFormData(
      formData.get("CardNo"),
      formData.get("MM/YY"),
      formData.get("CVC"),
      formattedDate,
      discountData,
      formData.get("ServiceType"),
      formData.get("TypeOfCleaning"),
      formData.get("No_of_Bed"),
      formData.get("Hours"),
      formData.get("Fname"),
      formData.get("Lname"),
      formData.get("Phone"),
      formData.get("Email"),
      formData.get("Address"),
      formData.get("Suburb"),
      formData.get("State"),
      formData.get("Post_Code"),
      Discount,
      total,
      extras.join(", ")
    );

    //Set loading and alert false after email
    setTimeout(() => {
      setLoading(false);
      setAlert(false);
    }, 5000);

    form.current.reset();
  };

  const postFormData = async (
    card_number,
    expiry_date,
    cvc,
    selected_date,
    discount,
    service_type,
    cleaning_type,
    bed_count,
    hour_count,
    f_name,
    l_name,
    phone,
    email,
    street,
    suburb,
    state,
    postcode,
    package_name,
    total_price,
    extra
  ) => {
    try {
      const response = await axios.post(`${apiUrl}`, {
        card_number: card_number,
        expiry_date: expiry_date,
        cvc: cvc,
        selected_date: selected_date,
        discount: discount,
        service_type: service_type,
        cleaning_type: cleaning_type,
        bed_count: bed_count,
        hour_count: hour_count,
        f_name: f_name,
        l_name: l_name,
        phone: phone,
        email: email,
        street: street,
        suburb: suburb,
        state: state,
        postcode: postcode,
        package_name: package_name,
        total_price: total_price.toString(),
        extra: extra,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className={styles.outerSection}>
        <div className={styles.LeftSection}>
          <div className={styles.TopTextsection}>
            <h1>Secure Your Spot With Us</h1>
            <p>
              Spark Clean is open and excited to welcome new customers with the
              mission of providing a quality service! Secure your spot now
              before it's gone.
            </p>
            <br />
            <p>
              Service Hours: 8am - 5pm <br /> (We'll confirm your specific time
              slot after booking.)
            </p>
            <br />
            <p>
              *Book by filling out the fields marked with. <br /> (Required for
              booking confirmation.)
            </p>
            <br />
            <p>
              If you have any problems filling out the form below, please call
              us at 0493 922 822.
            </p>
            {/* <p>
              COVID-Safe Plan All members of our team will strictly follow
              government COVID regulations current at the time of your booking.
            </p> */}
          </div>
          <form ref={form} onSubmit={(e) => handleSubmitEmail(e)}>
            <div className={styles.Step}>
              <h2>STEP 1: Who You Are</h2>
              <p>
                This information will be used to contact you about your service
              </p>
              <div className={styles.PersonalDiv}>
                <div className={styles.griditem}>
                  <input
                    type="text"
                    name="Fname"
                    placeholder="First Name*"
                    onChange={handleChangeFirstName}
                    required
                    pattern=".{2,}"
                  />
                  {FnameError && (
                    <div className={styles.error}>{FnameError}</div>
                  )}
                </div>
                <div className={styles.griditem}>
                  <input
                    type="text"
                    name="Lname"
                    pattern=".{2,}"
                    placeholder="Last Name*"
                    required
                    onChange={handleChangeLastName}
                  />
                  {LnameError && (
                    <div className={styles.error}>{LnameError}</div>
                  )}
                </div>
                <div className={styles.griditem}>
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email*"
                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                    onChange={handleEmailPattern}
                    required
                  />
                  {emailError && (
                    <div className={styles.error}>{emailError}</div>
                  )}
                </div>
                <div className={styles.griditem}>
                  <input
                    type="tel" // Use type="tel" for phone numbers
                    name="Phone"
                    placeholder="Phone*"
                    pattern="\d{10}"
                    title="Phone number must be exactly 10 digits"
                    value={phone}
                    onChange={handleChangePhone}
                    required
                  />
                  {phoneError && (
                    <div className={styles.error}>{phoneError}</div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.Step}>
              <h2>STEP 2: Your Address</h2>
              <p>Where would you like us to clean?</p>
              <div className={styles.HomeDiv}>
                <div className={`${styles.griditem} ${styles.span3}`}>
                  <input
                    type="text"
                    name="Address"
                    placeholder="Address*"
                    pattern=".{2,}"
                    required
                    onChange={handleChangeAddress}
                  />
                  {addressError && (
                    <div className={styles.error}>{addressError}</div>
                  )}
                </div>
                <div className={styles.griditem}>
                  <input
                    type="number"
                    name="Apt_UnitNo"
                    placeholder="Apt/UnitNo."
                    required
                  />
                </div>
                <div className={`${styles.griditem} ${styles.span2}`}>
                  <input
                    type="text"
                    name="Suburb"
                    placeholder="Suburb*"
                    onChange={handleChangeSuburb}
                    pattern=".{2,}"
                    required
                  />
                  {suburbError && (
                    <div className={styles.error}>{suburbError}</div>
                  )}
                </div>
                <div className={styles.griditem}>
                  <select id="State" name="State">
                    <option value="" disabled selected>
                      State*
                    </option>
                    <option value="ACT">ACT</option>
                    <option value="NSW">NSW</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                  </select>
                </div>
                <div className={styles.griditem}>
                  <input
                    type="text"
                    name="Post_Code"
                    placeholder="Post Code*"
                    pattern="[0-9]+"
                    title="Post Code must contain only numeric characters (0-9)"
                    required
                  />
                </div>
              </div>
            </div>
            <div className={styles.Step}>
              <h2>STEP 3: Choose Your Service</h2>

              <div className={styles.ServiceDiv}>
                <div className={`${styles.griditem} ${styles.span2}`}>
                  <p style={{ marginBottom: "10px" }}>
                    Pick the type of Service you want.
                  </p>
                  <select
                    id="TypeOfCleaning"
                    name="TypeOfCleaning"
                    onChange={handleTypeOfCleaning}
                    value={typeOfCleaningSelected}
                    required
                  >
                    <option value="" disabled hidden>
                      Select Type of Cleaning
                    </option>
                    <option value="Standard Cleaning">Standard Cleaning</option>
                    <option value="Move In/Out Clean">Move In/Out Clean</option>
                    <option value="Deep Cleaning">Deep Cleaning</option>
                  </select>
                </div>

                {/* IF HOURLY SERVICE SELECTED */}

                {typeOfCleaningSelected === "Standard Cleaning" ? (
                  <>
                    <div className={`${styles.griditem} ${styles.span2}`}>
                      <p style={{ marginBottom: "10px" }}>
                        Do you want a Hourly Service or Flat Rates . Choose Here
                      </p>
                      <select
                        id="ServiceType"
                        name="ServiceType"
                        onChange={handleChangeService}
                        value={serviceSelected}
                        required
                      >
                        <option value="" disabled hidden>
                          Select your Service
                        </option>
                        <option value="Flat Rate">Flat Rate</option>

                        <option value="Hourly Service">
                          Hourly Service - $58/h
                        </option>
                      </select>
                    </div>
                    <div
                      className={
                        serviceSelected === "Hourly Service"
                          ? styles.griditem
                          : `${styles.griditem} ${styles.noneDisplay}`
                      }
                    >
                      <select
                        id="BedsTime"
                        name="Hours"
                        onChange={handleHoursonChange}
                        value={hoursSelected}
                        disabled={serviceSelected !== "Hourly Service"}
                      >
                        <option value="" disabled hidden>
                          Select No. of Hours
                        </option>
                        <option value="2.5">2.5 Hours</option>
                        <option value="3">3 Hours</option>
                        <option value="3.5">3.5 Hours</option>
                        <option value="4">4 Hours</option>
                        <option value="4.5">4.5 Hours</option>
                        <option value="5">5 Hours</option>
                        <option value="5.5">5.5 Hours</option>
                        <option value="6">6 Hours</option>
                        <option value="6.5">6.5 Hours</option>
                        <option value="7">7 Hours</option>
                        <option value="7.5">7.5 Hours</option>
                        <option value="8">8 Hours</option>
                        <option value="8.5">8.5 Hours</option>
                        <option value="9">9 Hours</option>
                        <option value="9.5">9.5 Hours</option>
                        <option value="10">10 Hours</option>
                        <option value="10.5">10.5 Hours</option>
                        <option value="11">11 Hours</option>
                        <option value="11.5">11.5 Hours</option>
                        <option value="12">12 Hours</option>
                        <option value="12.5">12.5 Hours</option>
                        <option value="13">13 Hours</option>
                        <option value="13.5">13.5 Hours</option>
                        <option value="14">14 Hours</option>
                        <option value="14.5">14.5 Hours</option>
                        <option value="15">15 Hours</option>
                        <option value="15.5">15.5 Hours</option>
                        <option value="16">16 Hours</option>
                        <option value="16.5">16.5 Hours</option>
                        <option value="17">17 Hours</option>
                        <option value="17.5">17.5 Hours</option>
                        <option value="18">18 Hours</option>
                        <option value="18.5">18.5 Hours</option>
                        <option value="19">19 Hours</option>
                        <option value="19.5">19.5 Hours</option>
                        <option value="20">20 Hours</option>
                      </select>
                    </div>
                  </>
                ) : (
                  /* IF OTHER CLEANING IS SELECTED */
                  <div className={`${styles.griditem} ${styles.span2}`}>
                    <p style={{ marginBottom: "10px" }}>
                      Do you want a Hourly Service or Flat Rates . Choose Here
                    </p>
                    <select
                      id="ServiceType"
                      name="ServiceType"
                      onChange={handleChangeService}
                      value={"Flat Rate"}
                      required
                    >
                      <option value="" disabled hidden>
                        Select your Service
                      </option>
                      <option value="Flat Rate">Flat Rate</option>
                      {/* {typeOfCleaningSelected === "Standard Cleaning" && (
                          <option value='Hourly Service'>
                            Hourly Service - $58/h
                          </option>
                        )} */}
                    </select>
                  </div>
                )}
                {/* FLAT RATE */}
                <div className={styles.griditem}>
                  <select
                    id="Beds"
                    name="No_of_Bed"
                    onChange={handleBedonChange}
                    value={bedSelected}
                    disabled={serviceSelected !== "Flat Rate"}
                    hidden={serviceSelected === "Hourly Service"}
                  >
                    <option value="" disabled hidden>
                      Select No. of Beds
                    </option>
                    <option value="1 Bed 1 Bath">1 Bed 1 Bath</option>
                    <option value="2 Bed 1 Bath">2 Bed 1 Bath </option>
                    <option value="3 Bed 1 Bath">3 Bed 1 Bath</option>
                    <option value="4 Bed 1 Bath">4 Bed 1 Bath</option>
                    <option value="5 Bed 1 Bath">5 Bed 1 Bath</option>
                    <option value="6 Bed 1 Bath">6 Bed 1 Bath</option>
                    {typeOfCleaningSelected !== "Standard Cleaning" && (
                      <option value="7 Bed 1 Bath">7 Bed 1 Bath</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            {serviceSelected === "Flat Rate" ? (
              <div className={styles.Step}>
                <h2>STEP 4-1: Select Extras</h2>
                <strong>
                  Our move-in/out cleaning services are priced differently from
                  our standard cleaning, especially for bathrooms and additional
                  stories.
                </strong>
                <p>
                  <br />
                  For flat rates, we highly recommend opting for the extra
                  services mentioned below:
                </p>
                <div className={styles.ExtraDiv}>
                  <IconContext.Provider value={{ size: "70%" }}>
                    {/* ADDITIONAL FULL BATHROOM CLEAN */}
                    {typeOfCleaningSelected === "Standard Cleaning" ||
                    typeOfCleaningSelected === "Deep Cleaning" ||
                    typeOfCleaningSelected === "Move In/Out Clean" ? (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput12
                              ? value12 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(12)}
                        >
                          <MdBathroom />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput12
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="FullBathroom"
                          onChange={handleInputChange12}
                          value={value12}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput12
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange12}
                          min='0'
                          max='6'
                          name='FullBathroom'
                          value={value12}
                        /> */}
                        <p>
                          Additional Full Bathrom -{" "}
                          {typeOfCleaningSelected === "Standard Cleaning" ||
                          typeOfCleaningSelected === "Deep Cleaning"
                            ? "$22 per"
                            : "$25 per"}{" "}
                          ({value12})
                        </p>
                        <span className={styles.tooltiptext}>
                          Step into the realm of relaxation and cleanliness with
                          our additional full bathroom cleaning services! If you
                          require additional bathroom cleaning, we have you
                          covered. Our professional staff will clean your
                          bathroom.
                        </span>
                      </div>
                    ) : null}

                    {/* ADDITIONAL HALF BATHROOM CLEAN */}

                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput13
                              ? value13 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(13)}
                        >
                          <MdOutlineBathroom />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput13
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="HalfBathroom"
                          onChange={handleInputChange13}
                          value={value13}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput13
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange13}
                          min='0'
                          max='6'
                          name='HalfBathroom'
                          value={value13}
                        /> */}
                        <p>Additional Half Bathroom - $15 ({value13})</p>
                        <span className={styles.tooltiptext}>
                          Cleaning a half-bathroom is a delicate balance of
                          precision and thoroughness. Begin with the sink and
                          work down to the toilet and the floor. Leaving your
                          bathroom clean and flawless professionally.
                        </span>
                      </div>
                    )}

                    {/* CLEAN REFRIGERATOR */}
                    <div
                      className={
                        typeOfCleaningSelected === "Standard Cleaning" ||
                        typeOfCleaningSelected === "Deep Cleaning"
                          ? `${styles.griditem} ${styles.tooltip}`
                          : styles.noneDisplay
                      }
                    >
                      <div
                        className={
                          !showInput1
                            ? value1 > 0
                              ? `${styles.icons} ${styles.clicked}`
                              : styles.icons
                            : styles.noneDisplay
                        }
                        onClick={() => handleIconsClick(1)}
                      >
                        <RiFridgeFill />
                      </div>
                      <NumberInput
                        defaultValue={0}
                        min={0}
                        max={6}
                        size="lg"
                        className={
                          showInput1 ? styles.spinnerinput : styles.noneDisplay
                        }
                        name="CleanRefrigerator"
                        onChange={handleInputChange1}
                        value={value1}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      {/* <input
                        type='number'
                        className={
                          showInput1 ? styles.spinnerinput : styles.noneDisplay
                        }
                        onChange={handleInputChange1}
                        min='0'
                        max='6'
                        name='CleanRefrigerator'
                        value={value1}
                        id='1'
                      /> */}
                      <p>Clean Refrigerator - $45 ({value1})</p>
                      <span className={styles.tooltiptext}>
                        Unwind and leave your refrigerator to us! Our cautious
                        staff will thoroughly clean every shelf, drawer, and
                        section of your refrigerator, leaving it flawless and
                        hygienic. With confidence, say goodbye to mysterious
                        spills and strange odors!
                      </span>
                    </div>

                    {/* OVEN CLEANING */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput2
                              ? value2 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(2)}
                        >
                          <LuMicrowave />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput2
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="OvenCleaning"
                          onChange={handleInputChange2}
                          value={value2}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput2
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange2}
                          min='0'
                          max='6'
                          name='OvenCleaning'
                          value={value2}
                        /> */}
                        <p>Oven Cleaning - $45 ({value2})</p>
                        <span className={styles.tooltiptext}>
                          Prepare for an element of magic in your oven-cleaning
                          experience! Our skilled crew is determined to restore
                          the shine to your oven. Note: There may be limitations
                          to achieving a sparkling finish in older ovens, but no
                          removal is required.
                        </span>
                      </div>
                    )}

                    {/* SMALL BALCONY */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput3
                              ? value3 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(3)}
                        >
                          <MdBalcony />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput3
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="SmallBalcony"
                          onChange={handleInputChange3}
                          value={value3}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput3
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange3}
                          min='0'
                          max='6'
                          name='SmallBalcony'
                          value={value3}
                        /> */}
                        <p>Small Balcony - $32 ({value3})</p>
                        <span className={styles.tooltiptext}>
                          We're committed to sweeping, tidying, and mopping your
                          small balcony thoroughly! Please note, that this
                          service doesn't cover cleaning the outside glass of
                          balcony doors or glass balcony. If you require
                          cleaning for these areas, simply add additional
                          interior windows to the balcony or reach out to our
                          office for a detailed discussion.
                        </span>
                      </div>
                    )}

                    {/* LARGE BALCONY */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput4
                              ? value4 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(4)}
                        >
                          <MdBalcony />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput4
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="LargeBalcony"
                          onChange={handleInputChange4}
                          value={value4}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput4
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange4}
                          min='0'
                          max='6'
                          name='LargeBalcony'
                          value={value4}
                        /> */}
                        <p>Large Balcony - $63 ({value4})</p>
                        <span className={styles.tooltiptext}>
                          We will thoroughly sweep, tidy, and mop your large
                          balcony! Note that this does not include the outside
                          glass of the balcony doors or the balconies made of
                          glass. For additional interior windows to the balcony
                          give our office a call to have a conversation.
                        </span>
                      </div>
                    )}

                    {/* INTERIOR WINDOW */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput5
                              ? value5 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(5)}
                        >
                          <MdOutlineWindow />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput5
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="InteriorWindow"
                          onChange={handleInputChange5}
                          value={value5}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput5
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange5}
                          min='0'
                          max='6'
                          name='InteriorWindow'
                          value={value5}
                        /> */}
                        <p>Interior Window ($20/room)({value5})</p>
                        <span className={styles.tooltiptext}>
                          Clean your space with our interior window service for
                          just $20 per room! We remove dust, smudges, and grime
                          from windows using top-tier cleaning solutions and
                          techniques, revealing crystal-clear windows that
                          invite natural light to cascade into your rooms. Our
                          dedicated team leaves no streak or spot unnoticed from
                          corner to corner.
                        </span>
                      </div>
                    )}

                    {/* CARPET STEAM CLEAN */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput6
                              ? value6 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(6)}
                        >
                          <GiRedCarpet />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput6
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="CarpetSteamClean"
                          onChange={handleInputChange6}
                          value={value6}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput6
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange6}
                          min='0'
                          max='6'
                          name='CarpetSteamClean'
                          value={value6}
                        /> */}

                        <p>Carpet Steam Clean - $90 ({value6})</p>
                        <span className={styles.tooltiptext}>
                          Shine your carpet at an affordable price with steam
                          clean! We remove all the carpet dust, smudges, and
                          unwanted stains leaving it immaculate. When it comes
                          to providing quality services to our customers, our
                          dedicated team makes no exceptions.
                        </span>
                      </div>
                    )}

                    {/* CEILING TO FLOOR GLASS WINDOW */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput8
                              ? value8 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(8)}
                        >
                          <ImMoveDown />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput8
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="CeilingFloor"
                          onChange={handleInputChange8}
                          value={value8}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput8
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange8}
                          min='0'
                          max='10'
                          name='CeilingFloor'
                          value={value8}
                        /> */}

                        <p>
                          Ceiling to Floor Glass Window ($18/glass) ({value8})
                        </p>
                        <span className={styles.tooltiptext}>
                          Find out how our ceiling-to-floor window cleaning
                          services can change your life! Glamorous shine and
                          open views that invite natural light to stream through
                          your windows will elevate your space. Our methodical
                          approach guarantees that every window sparkles,
                          elevating your atmosphere and creating a memorable
                          effect.
                        </span>
                      </div>
                    )}

                    {/* DECK CLEANING */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            value9 === "yes"
                              ? `${styles.icons} ${styles.clicked}`
                              : styles.icons
                          }
                          onClick={() => handleIconsClick(9)}
                        >
                          <MdDeck />
                        </div>
                        <input
                          type="text"
                          className={styles.spinnerinput}
                          onChange={handleInputChange9}
                          min="0"
                          name="Deck"
                          value={value9}
                          style={{ display: "none" }}
                        />
                        <p>Deck Cleaning - $33</p>
                        <span className={styles.tooltiptext}>
                          Deck cleaning services can transform your outdoor
                          space into a haven of relaxation and entertainment.
                          These services cater to your vision, whether it's for
                          a cozy wooden deck or a stylish composite platform for
                          hosting gatherings professionally.
                        </span>
                      </div>
                    )}

                    {/* EXTRA DIRTY */}
                    {typeOfCleaningSelected === "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            value11 === "yes"
                              ? `${styles.icons} ${styles.clicked}`
                              : styles.icons
                          }
                          onClick={() => handleIconsClick(11)}
                        >
                          <MdOutlineWindow />
                        </div>
                        <input
                          type="text"
                          className={styles.spinnerinput}
                          onChange={handleInputChange11}
                          min="0"
                          name="ExtraDirty"
                          value={value11}
                          style={{ display: "none" }}
                        />
                        <p>Extra Dirty - $80</p>
                        <span className={styles.tooltiptext}>
                          Grime Meets its Match with Extra Dirty Cleaning
                          Services! We're not afraid to get our hands dirty in
                          order to quickly transform your space from chaos to
                          clean. We turn grimy into gleaming with our extreme
                          attention to detail and knack for tackling even the
                          most difficult messes, making your satisfaction our
                          top priority.
                        </span>
                      </div>
                    )}

                    {/* EXTRA STOREY */}
                    {typeOfCleaningSelected === "Standard Cleaning" ||
                    typeOfCleaningSelected === "Deep Cleaning" ||
                    typeOfCleaningSelected === "Move In/Out Clean" ? (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput18
                              ? value18 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(18)}
                        >
                          <GiFamilyHouse />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput18
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="ExtraStorey"
                          onChange={handleInputChange18}
                          value={value18}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput18
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange18}
                          min='0'
                          max='6'
                          name='ExtraStorey'
                          value={value18}
                        /> */}
                        <p>
                          Extra Storey
                          {typeOfCleaningSelected === "Standard Cleaning" ||
                          typeOfCleaningSelected === "Deep Cleaning"
                            ? "($40/storey)"
                            : "($50/storey)"}
                          ({value18})
                        </p>
                        <span className={styles.tooltiptext}>
                          Extra Storey Cleaning Services adds a magical touch to
                          your space. Our team elevates cleanliness to an art
                          form with meticulous attention to detail and a
                          commitment to sparkle. With our expert touch, you can
                          say goodbye to dust bunnies and hello to clean
                          surfaces.
                        </span>
                      </div>
                    ) : null}

                    {/* EXTERIOR WINDOW */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput14
                              ? value14 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(14)}
                        >
                          <MdOutlineSensorWindow />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput14
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="ExteriorWindow"
                          onChange={handleInputChange14}
                          value={value14}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput14
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange14}
                          min='0'
                          max='10'
                          name='ExteriorWindow'
                          value={value14}
                        /> */}
                        <p>Exterior Window ($15/window) ({value14})</p>
                        <span className={styles.tooltiptext}>
                          Clean your space with our exterior window services at
                          a reasonable price! We provide reliable outdoor window
                          washing for homes, offices, stores, and other places
                          providing services according to your needs and
                          requirements.
                        </span>
                      </div>
                    )}

                    {/* INSIDE CUPBOARD */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput15
                              ? value15 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(15)}
                        >
                          <RiFridgeFill />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput15
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="InsideCupboard"
                          onChange={handleInputChange15}
                          value={value15}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput15
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange15}
                          min='0'
                          max='6'
                          name='InsideCupboard'
                          value={value15}
                        /> */}

                        <p>Inside Cupboard - $55 (Must be Empty) ({value15})</p>
                        <span className={styles.tooltiptext}>
                          Our professional staff will leave your cupboard
                          spotless for only $55. For security and privacy
                          reasons, the inside of the cupboard must be secure.
                          Aside from that, you don't have to stress about
                          cleaning your cupboard.
                        </span>
                      </div>
                    )}

                    {/* CHANGE BEDSHEET */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput16
                              ? value16 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(16)}
                        >
                          <BiSolidBed />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput16
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="ChangeBedsheet"
                          onChange={handleInputChange16}
                          value={value16}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput16
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange16}
                          min='0'
                          max='10'
                          name='ChangeBedsheet'
                          value={value16}
                        /> */}

                        <p>Change Bedsheet - $15 ({value16})</p>
                        <span className={styles.tooltiptext}>
                          With our brand-new sheets, you can upgrade your bed
                          for just $15 per bed! Witness the transformation as we
                          replace your old sheets with new ones, allowing you to
                          immerse yourself in a world of relaxation and elevate
                          your bedtime to pure luxury!
                        </span>
                      </div>
                    )}

                    {/* WET WIPE BLINDS */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput17
                              ? value17 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(17)}
                        >
                          <MdBlinds />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput17
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="WetWipeBlinds"
                          onChange={handleInputChange17}
                          value={value17}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput17
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange17}
                          min='0'
                          max='10'
                          name='WetWipeBlinds'
                          value={value17}
                        /> */}
                        <p>Wet Wipe Blinds - $33/fixture ({value17})</p>
                        <span className={styles.tooltiptext}>
                          Say goodbye to worries about blind cleaning—we've got
                          it all under control! Our careful handling guarantees
                          that the curtains are thoroughly cleaned. While
                          extremely thin metallic Venetian blinds might pose a
                          challenge, we'll handle them delicately to avoid any
                          breakage. Rest assured, we'll discuss any concerns
                          with you on the day to find the best solution!
                        </span>
                      </div>
                    )}

                    {/* SPOT CLEAN WALLS */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            !showInput7
                              ? value7 > 0
                                ? `${styles.icons} ${styles.clicked}`
                                : styles.icons
                              : styles.noneDisplay
                          }
                          onClick={() => handleIconsClick(7)}
                        >
                          <GiBrickWall />
                        </div>
                        <NumberInput
                          defaultValue={0}
                          min={0}
                          max={6}
                          size="lg"
                          className={
                            showInput7
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          name="SpotCleanWalls"
                          onChange={handleInputChange7}
                          value={value7}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        {/* <input
                          type='number'
                          className={
                            showInput7
                              ? styles.spinnerinput
                              : styles.noneDisplay
                          }
                          onChange={handleInputChange7}
                          min='0'
                          max='6'
                          name='SpotCleanWalls'
                          value={value7}
                        /> */}

                        <p>Spot Clean Walls ($35/room) ({value7})</p>
                        <span className={styles.tooltiptext}>
                          Spot Clean Walls Services specializes in the
                          restoration and maintenance of interior walls for a
                          flawless environment. Our cautious team employs
                          advanced techniques and tailored cleaning agents. We
                          deliver outstanding outcomes while preserving wall
                          integrity, from stubborn stains to general wear. Our
                          service revitalizes walls, enhancing your home or
                          workspace with new surfaces and a refreshed ambiance.
                        </span>
                      </div>
                    )}

                    {/* PATIO */}
                    {typeOfCleaningSelected !== "Move In/Out Clean" && (
                      <div className={`${styles.griditem} ${styles.tooltip}`}>
                        <div
                          className={
                            value10 === "yes"
                              ? `${styles.icons} ${styles.clicked}`
                              : styles.icons
                          }
                          onClick={() => handleIconsClick(10)}
                        >
                          <PiTreeEvergreenFill />
                        </div>
                        <input
                          type="text"
                          className={styles.spinnerinput}
                          onChange={handleInputChange10}
                          name="Patio"
                          value={value10}
                          style={{ display: "none" }}
                        />
                        <p>Patio - $33</p>
                        <span className={styles.tooltiptext}>
                          Revitalize your outdoor space with our top-notch patio
                          cleaning services! Say goodbye to the stubborn stains
                          and grime that have been hiding your patio's beauty.
                          Our expert team uses the most up-to-date techniques to
                          ensure a sparkling clean, welcoming you to enjoy your
                          outdoor haven with ease. Witness the transformation
                          and watch your patio shine brightly once more!
                        </span>
                      </div>
                    )}
                  </IconContext.Provider>
                </div>
              </div>
            ) : null}

            {/* TIME SELECTION */}
            <div className={styles.Step}>
              <h2>
                {serviceSelected === "Flat Rate" ? "STEP 4-2: " : "STEP 4-1: "}
                When would you like us to arrive?
              </h2>
              <p>
                Note: Full address must be entered above for booking spots to
                populate.
              </p>
              <br />
              <p>
                The cleaners will arrive within the allocated time slots. If
                possible, please grant us access to your property when no one is
                inside. If you have spare keys, ask a member of staff to give
                them to us.
              </p>{" "}
              <p>
                If you need an exact arrival time, kindly specify in the
                comments section below.
              </p>
              {/* <p>
                We may be in touch to request a different date/time before
                confirming your service.
              </p> */}
              <div className={styles.PersonalDiv}>
                <div className={styles.griditem}>
                  {/* <input type='text' name="Fname" placeholder='Click to Choose a Date' required /> */}
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="Choose your date"
                    className={styles.DatePicker}
                    minDate={minDate}
                    name="Date"
                    autoComplete="off"
                    value={selectedDate}
                    filterDate={(date) => {
                      // Disable weekends ( Sunday )
                      return date.getDay() !== 0;
                    }}
                    required
                  />
                  <p id={styles.dateDiv} style={{ fontSize: "15px" }}>
                    MM / DD / YYYY
                  </p>
                </div>
                <div className={styles.griditem}>
                  {/* <select id="Time" name="Time" >
                                        {/* <option value="" disabled hidden>Select a time</option> */}
                  {/* {selectedDate && ( */}
                  {/* <>
                                            <option value="8am-7pm">8am - 7pm</option>
                                        </> */}

                  {/* </select> */}
                  <input
                    type="text"
                    name="Time"
                    placeholder="Time"
                    value={valueTime}
                    disabled
                  />
                  <p style={{ fontSize: "15px" }}>
                    We will get back to you shortly for time confirmation.
                  </p>
                </div>
              </div>
            </div>

            {/* TIME SELECTION */}
            {typeOfCleaningSelected === "Move In/Out Clean" ? (
              <></>
            ) : (
              <div className={styles.Step}>
                <h2>STEP 5: How often?</h2>
                <p>
                  It's all about matching you with the perfect clean for your
                  home. Scheduling is flexible. Cancel or reschedule anytime.
                </p>
                <strong>
                  **Please note discounts occur after first service**
                </strong>
                <div className={styles.DiscountDiv}>
                  <div className={styles.griditem}>
                    <div
                      className={
                        Discount === "One Time Cleaning"
                          ? ` ${styles.ColorDiv}`
                          : styles.Discountbtn
                      }
                      onClick={() => handleDiscountClick("One Time Cleaning")}
                    >
                      One Time Cleaning
                    </div>
                  </div>
                  <div className={styles.griditem}>
                    <div
                      className={
                        Discount === "Weekly - 15% Off"
                          ? ` ${styles.ColorDiv}`
                          : styles.Discountbtn
                      }
                      onClick={() => handleDiscountClick("Weekly - 15% Off")}
                    >
                      Weekly Clean - 15% Off
                    </div>
                  </div>
                  <div className={styles.griditem}>
                    <div
                      className={
                        Discount === "Fortnightly - 10% Off"
                          ? ` ${styles.ColorDiv}`
                          : styles.Discountbtn
                      }
                      onClick={() =>
                        handleDiscountClick("Fortnightly - 10% Off")
                      }
                    >
                      Fortnightly Clean - 10% Off
                    </div>
                  </div>
                  <div className={styles.griditem}>
                    <div
                      className={
                        Discount === "Monthly - 5% Off"
                          ? ` ${styles.ColorDiv}`
                          : styles.Discountbtn
                      }
                      onClick={() => handleDiscountClick("Monthly - 5% Off")}
                    >
                      Monthly Clean - 5% Off
                    </div>
                  </div>
                  <input hidden name="Discount" value={Discount} />
                </div>
              </div>
            )}
            <div className={`${styles.Step} ${styles.ExtraStep}`}>
              <h2>Important Extra Information</h2>
              <p>
                Some of our cleaning teams have allergies or other reasons why
                they can't attend homes with pets.
              </p>
              <div className={styles.ExtraInfoDiv}>
                <div className={styles.griditem}>
                  <label>Do you have pets? *</label>

                  <select id="Pets" name="Pets" required>
                    <option value=""></option>

                    <option value="Dog">Yes - Dogs</option>
                    <option value="Cat">Yes - Cats</option>
                    <option value="No">No Pets</option>
                    <option value="Dog&Cat">Yes - Dogs & Cats</option>
                    <option value="Others">Yes - Others</option>
                  </select>
                </div>
                <p>
                  If you have time, please let us know as much of the below as
                  possible. If not, our team may need to get in touch with you
                  prior to confirming your service.
                </p>
                <div className={styles.griditem}>
                  <label>How will we get inside your home?</label>
                  <select id="Howto" name="How_to_get_inside_home">
                    <option value=""></option>

                    <option value="I will be home">I will be home</option>
                    <option value="I will leave a key">
                      I will leave a key
                    </option>
                    <option value="I will provide a lock box/access key">
                      I will provide a lock box/access key
                    </option>
                    <option value="Other - Please Explain Below">
                      Other - Please Explain Below
                    </option>
                  </select>
                </div>
                <div className={styles.griditem}>
                  <label>Where can we park?</label>
                  <select id="Parking" name="Parking">
                    <option value=""></option>

                    <option value="I will provide parking on-site">
                      I will provide parking on-site
                    </option>
                    <option value="Free Street Parking">
                      Free Street Parking
                    </option>
                    <option value="Paid Street Parking">
                      Paid Street Parking
                    </option>
                    <option value="Other - Please Explain Below">
                      Other - Please Explain Below
                    </option>
                  </select>
                </div>
                <div className={styles.griditem}>
                  <label>Is your date/time flexible?</label>
                  <select id="State" name="DateTimeFlexibility">
                    <option value=""></option>

                    <option value="Yes - Date and Time">
                      Yes - Date and Time
                    </option>
                    <option value="Yes - Date but not Time">
                      Yes - Date but not Time
                    </option>
                    <option value="Yes - Time but not Date">
                      Yes - Time but not Date
                    </option>
                    <option value="No - Not Flexible">No - Not Flexible</option>
                    <option value="Yes - See notes">Yes - See notes</option>
                  </select>
                </div>
                <div className={styles.griditem}>
                  <label>
                    For regular services, if your Standard Cleaning team is not
                    available, how would you like us to proceed with your
                    service?
                  </label>
                  <select id="RegularService" name="RegularService">
                    <option value=""></option>

                    <option value="Arrange a cleaner to cover and do not bother you">
                      Arrange a cleaner to cover and do not bother you
                    </option>
                    <option value="Arrange a cleaner to cover and give you the heads up">
                      Arrange a cleaner to cover and give you the heads up
                    </option>
                    <option value="Contact you to discuss options">
                      Contact you to discuss options
                    </option>
                  </select>
                </div>
                <div className={styles.griditem}>
                  <label>
                    When was your house last fully or professionally cleaned?
                  </label>
                  <select id="LastCleaned" name="LastCleaned">
                    <option value=""></option>
                    <option value="Within 1-2 weeks">Within 1-2 weeks</option>
                    <option value="Within 2-4 weeks">Within 2-4 weeks</option>
                    <option value="Within 1-4 months">Within 1-4 months</option>
                    <option value="Over 4 months ago">Over 4 months ago</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.Step}>
              <p style={{ fontSize: "18px" }}>General Comments</p>
              <textarea
                type="text"
                name="Comments"
                rows="5"
                placeholder="If there is anything else we should know, please pop it in here. For hourly rates - please let us know your list of priorities so we focus on what us important :-)"
              />
            </div>
            <div className={styles.Step}>
              <h2>STEP 6: Select Payment</h2>
              <p>
                Don't worry - you will{" "}
                <strong>not be charged until after your service</strong>, once
                we have confirmed your are happy :-)
              </p>
              <div className={styles.griditem}>
                <IconContext.Provider value={{ color: "grey", size: "20px" }}>
                  <div className={styles.CardDetailsdiv}>
                    <div className={styles.IconInput}>
                      <div className={styles.cardLeft}>
                        <BsFillCreditCard2FrontFill id="cardIcon" />
                        <input
                          type="text"
                          name="CardNo"
                          value={cardNumber}
                          onChange={handleChange}
                          placeholder="Card number"
                          pattern="\d{4}( \d{4}){3}"
                          maxLength="19"
                          required
                        />
                      </div>
                      <div className={styles.cardRight}>
                        <input
                          type="text"
                          name="MM/YY"
                          value={MMYY}
                          onChange={handleChangeMY}
                          placeholder="MM / YY"
                          pattern="(0[1-9]|1[0-2])\/[0-9]{2}"
                          title="Please enter a valid MM/YY format (e.g., 06/24)"
                          required
                        />
                        <input
                          type="text"
                          name="CVC"
                          placeholder="CVC"
                          value={cvc}
                          onChange={handleChangeCVC}
                          maxLength="4"
                          required
                        />
                        {/* <input
                          type='text'
                          name='CVC'
                          // value={MMYY}
                          // onChange={handleChangeMY}
                          placeholder='CVC'
                          pattern='[0-9]{3}'
                          title='Please enter a valid 3-digit numeric CVC'
                          maxLength='3'
                          required
                        /> */}
                      </div>
                    </div>
                    {/* <div className={styles.cardText}>
                                          
                                        </div> */}
                  </div>
                  <img src={CardImage} alt="CardImg" />
                </IconContext.Provider>
              </div>
              <p className={styles.greyp}>
                I authorize Spark Clean Australia to charge my credit card above
                for agreed upon purchases. I understand that my information will
                be saved to file for further transactions on my account.
              </p>
            </div>
            <div className={`${styles.Step} ${styles.BookingDiv}`}>
              <div className={styles.RightFixedSection}>
                <div className={styles.cardheader}>BOOKING SUMMARY</div>
                <div className={styles.cardbody}>
                  <IconContext.Provider
                    value={{ color: "#1239ac", size: "3%" }}
                  >
                    <div className={styles.logospan}>
                      <BsFillHousesFill />
                      <span>{typeOfCleaningSelected}</span>
                    </div>
                    <div className={styles.logospan}>
                      <BsFillHousesFill />
                      <span>{serviceSelected}</span>
                    </div>
                    <div className={styles.logospan}>
                      <SlCalender />
                      <span>
                        {selectedDate
                          ? selectedDate.toLocaleDateString()
                          : "Choose service date..."}
                      </span>
                    </div>
                    <div className={styles.logospan}>
                      <GiCycle />
                      <span>{Discount}....</span>
                    </div>
                  </IconContext.Provider>
                </div>
                <div className={styles.cardFooter}>
                  <div className={styles.griditem}>
                    <p>SUB-TOTAL</p>
                  </div>
                  <div className={styles.griditem}>
                    <p className={styles.right}>${Price}</p>
                    <input hidden name="Price" value={Price} />
                  </div>
                  <div className={styles.griditem}>
                    <p>1.25% CC FEE</p>
                  </div>
                  <div className={styles.griditem}>
                    <p className={styles.right}>${CCFEE}</p>
                    <input hidden name="CCFEE" value={CCFEE} />
                  </div>
                  <div
                    className={
                      Discount === "One Time Cleaning"
                        ? `${styles.griditem} ${styles.noneDisplay}`
                        : styles.griditem
                    }
                  >
                    <h4>FIRST SERVICE</h4>
                  </div>
                  <div
                    className={
                      Discount === "One Time Cleaning"
                        ? `${styles.griditem} ${styles.noneDisplay}`
                        : styles.griditem
                    }
                  >
                    <h1 className={styles.right}>${FirstService}</h1>
                    <input hidden name="FirstService" value={FirstService} />
                  </div>

                  <div className={styles.griditem}>
                    {Discount === "One Time Cleaning" ? (
                      <h4>TOTAL</h4>
                    ) : (
                      <h4>ONGOING SERVICE</h4>
                    )}
                  </div>
                  <div className={styles.griditem}>
                    <h1 className={styles.right}>${total}</h1>

                    <input hidden name="total" value={total} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.Step}>
              <h4>Cancellation Policy</h4>
              <p className={styles.greyp}>
                Please note we have a 24-hour cancellation policy and any
                changes/cancellations within this time period will result in up
                to a 50% fee.
              </p>
              <p className={styles.greyp}>
                Remember - we don't charge a cent until after your service has
                taken place and we know you are happy!
              </p>
              <p className={styles.greyp}>
                Please remember all bookings via the website are requests and
                will be confirmed by our Team after checking availability.
              </p>
              <p className={styles.greyp}>
                By clicking the Book Now button you are agreeing to our Terms of
                Service and Privacy Policy.
              </p>
              <button
                className={styles.BookNow}
                type="submit"
                disabled={loading}
              >
                {loading ? <Spinner /> : "Book Now"}
              </button>
            </div>
          </form>
        </div>

        <div className={styles.RightSection}>
          <div className={styles.RightTopSection}>
            <IconContext.Provider value={{ color: "#1239ac", size: "18%" }}>
              <div className={styles.features}>
                <MdOutlineTimer />
                <h4>SAVING YOUR VALUABLE TIME</h4>
                <p>
                  The booking system from us is made to simplify your life and
                  free up valuable time for the things that count.
                </p>
              </div>
              <div className={styles.features}>
                <MdGppGood />
                <h4>PRIORITIZING SAFETY</h4>
                <p>
                  You can relax knowing that our cleaners undergo a rigorous
                  screening process that includes in-person interviews and
                  police checks to ensure your safety.
                </p>
              </div>
              <div className={styles.features}>
                <AiOutlineLike />
                <h4>TOP-NOTCH QUALITY</h4>
                <p>
                  Our experts always go above and beyond. To ensure a dedication
                  to quality, Cleaners receive ratings and reviews following
                  each task.
                </p>
              </div>
              <div className={styles.features}>
                <LiaHandsHelpingSolid />
                <h4>HASSLE-FREE ASSISTANCE</h4>
                <p>
                  Choose your postcode, the number of beds and hours, and the
                  date with just a few clicks, then sit back and unwind while we
                  care for your house.
                </p>
              </div>
              <div className={styles.features}>
                <MdConnectWithoutContact />
                <h4>SEAMLESS CONNECTIVITY</h4>
                <p>
                  Easily stay informed about your Cleaners with our easy-to-use
                  online communication tool.
                </p>
              </div>
              <div className={styles.features}>
                <RiVisaFill />
                <h4>PAYMENT FLEXIBILITY</h4>
                <p>Make your payment only when the cleaning is complete.</p>
              </div>
            </IconContext.Provider>
          </div>
          <div className={styles.RightFixedSection}>
            <div className={styles.cardheader}>BOOKING SUMMARY</div>
            <div className={styles.cardbody}>
              <IconContext.Provider value={{ color: "#1239ac", size: "10%" }}>
                <div className={styles.logospan}>
                  <BsFillHousesFill />
                  <span>{typeOfCleaningSelected}</span>
                </div>
                <div className={styles.logospan}>
                  <SlCalender />
                  <span>
                    {selectedDate
                      ? selectedDate.toLocaleDateString()
                      : "Choose service date..."}
                  </span>
                </div>
                <div className={styles.logospan}>
                  <GiCycle />
                  <span>{Discount}....</span>
                </div>
              </IconContext.Provider>
            </div>
            <div className={styles.cardFooter}>
              <div className={styles.griditem}>
                <p>SUB-TOTAL</p>
              </div>
              <div className={styles.griditem}>
                <p className={styles.right}>${Price}</p>
              </div>
              <div className={styles.griditem}>
                <p>1.25% CC FEE</p>
              </div>
              <div className={styles.griditem}>
                <p className={styles.right}>${CCFEE}</p>
              </div>
              <div
                className={
                  Discount === "One Time Cleaning"
                    ? `${styles.griditem} ${styles.noneDisplay}`
                    : styles.griditem
                }
              >
                <h4>FIRST SERVICE</h4>
              </div>
              <div
                className={
                  Discount === "One Time Cleaning"
                    ? `${styles.griditem} ${styles.noneDisplay}`
                    : styles.griditem
                }
              >
                <h1 className={styles.right}>${FirstService}</h1>
              </div>

              <div className={styles.griditem}>
                {Discount === "One Time Cleaning" ? (
                  <h4>TOTAL</h4>
                ) : (
                  <h4>ONGOING SERVICE</h4>
                )}
              </div>
              <div className={styles.griditem}>
                <h1 className={styles.right}>${total}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Book;
